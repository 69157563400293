import NumberGenerator from './components/NumberGenerator';
import FeedbackButton from './components/FeedbackButton';

const App = () => {
    return (
        <div>
            <NumberGenerator />
            <FeedbackButton />
        </div>
    );
};

export default App;
