import { useState } from 'react';

const apiUrl = process.env.REACT_APP_API_URL;
console.log("API URL:", process.env.REACT_APP_API_URL);

const NumberGenerator = () => {
    const [seed, setSeed] = useState('');
    const [iterations, setIterations] = useState('');
    const [numbers, setNumbers] = useState([]);
    const [steps, setSteps] = useState([]);
    const [showSteps, setShowSteps] = useState(false);
    const [error, setError] = useState('');

    const handleGenerate = async () => {
        setError('');
        setNumbers([]); // Limpia los números generados anteriores
        setSteps([]); // Limpia los pasos anteriores
        try {
            const response = await fetch(`${apiUrl}/middle-square/generate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    seed: parseInt(seed, 10),
                    iterations: parseInt(iterations, 10)
                })
            });

            const data = await response.json();
            if (response.ok) {
                if (Array.isArray(data.numbers)) {
                    setNumbers(data.numbers);
                    setSteps(data.pasos);
                } else {
                    setError(data.detail || "La respuesta de la API no es un arreglo:");
                }
            } else {
                setError(data.detail || "Error desconocido");
            }
        } catch (error) {
            console.error("Error fetching the numbers:", error);
            setError("Error fetching the numbers");
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Generador de Números Pseudoaleatorios</h1>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                    Semilla:
                    <input
                        type="number"
                        value={seed}
                        onChange={(e) => setSeed(e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    />
                </label>
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                    Iteraciones:
                    <input
                        type="number"
                        value={iterations}
                        onChange={(e) => setIterations(e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                    />
                </label>
            </div>
            <div className="mb-4">
                <label className='flex items-center'>
                    <input 
                        type='checkbox'
                        checked={showSteps}
                        onChange={(e) => setShowSteps(e.target.checked)}
                        className='mr-2'
                    />
                    <span className="text-sm font-medium text-gray-700">Mostrar pasos</span>
                </label>
            </div>
            <button
                onClick={handleGenerate}
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
            >
                Generar
            </button>
            {error && <p className="text-red-500 mt-4">{error}</p>}
            {numbers.length > 0 && (
                <div className="mt-6">
                    <h2 className="text-xl font-semibold mb-2">Números Generados:</h2>
                    <ul className="list-disc list-inside">
                        {numbers.map((num, index) => (
                            <li key={index}>{num}</li>
                        ))}
                    </ul>
                </div>
            )}
            {showSteps && steps.length > 0 && (
                <div className="mt-6">
                    <h2 className="text-xl font-semibold mt-6">Pasos del Cálculo:</h2>
                    <table className="table-auto w-full mt-4">
                        <thead>
                            <tr>
                                <th className="px-4 py-2">Cálculo</th>
                                <th className="px-4 py-2">X<sub>i</sub></th>
                                <th className="px-4 py-2">r<sub>i</sub></th>
                            </tr>
                        </thead>
                        <tbody>
                            {steps.map((step, index) => (
                                <tr key={index}>
                                    <td className="border px-4 py-2">{step.calculo}</td>
                                    <td className="border px-4 py-2">{step.Xi}</td>
                                    <td className="border px-4 py-2">{step.ri}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default NumberGenerator;
