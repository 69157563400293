// src/components/FeedbackButton.js

const FeedbackButton = () => {
    return (
        <button
            data-tally-open="3qBjp8"
            data-tally-emoji-text="👋"
            data-tally-emoji-animation="wave"
            data-tally-auto-close="2000"
            className="fixed bottom-4 right-4 p-3 bg-blue-500 text-white rounded-lg shadow-lg"
        >
            Enviar Feedback
        </button>
    );
};

export default FeedbackButton;
